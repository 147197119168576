import React from 'react'
import styled from 'styled-components'
import RelatedArticleCard from './RelatedArticleCard'

/*
 * The <RelatedArticleCards> component returns a <ul> element and should be used around <li> element(s)
 * The <li> element(s) are received as `props.children`
 */

/**
 * @example
 * <RelatedArticleCards>
 *   {cardItems.map(item => (
 *     <Card key={item.id} {...item} /> // <li> element(s)
 *   ))}
 * </RelatedArticleCards>
 */

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const RelatedArticleCards = ({ articles, ...props }) => {
  return (
    <List {...props}>
      {articles.map(({ node: article }) => (
        <RelatedArticleCard key={article.id} {...article} />
      ))}
    </List>
  )
}

export default RelatedArticleCards
