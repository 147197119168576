import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Container from '../components/Container'
import RelatedArticleCards from '../components/RelatedArticleCards'
import ResponsiveVideo from '../components/ResponsiveVideo'
import SEO from '../components/SEO'
import { PageContext } from '../context/ApplicationState'
import config from '../utils/siteConfig'

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  .label {
    margin-bottom: 0;
  }

  .headline--large {
    max-width: none;
    margin-top: 10px;
    margin-bottom: 5px;

    + .detail {
      margin-top: 0;
    }
  }

  .headline--intro {
    margin: 0;
  }

  .detail {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .gatsby-img-container,
  .gatsby-image-wrapper {
    width: 100%;
  }

  .gatsby-img-container {
    figure {
      margin: 0;
    }
  }

  .caption {
    text-align: left;
    margin-top: 10px;
  }

  + .body-content {
    margin-top: ${props => props.theme.section.topSm};
    margin-bottom: ${props => props.theme.section.bottomSm};
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    .headline--large {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .detail {
      margin-bottom: 60px;
    }

    + .body-content {
      margin-top: calc(${props => props.theme.section.topLg} * 0.75);
      margin-bottom: ${props => props.theme.section.bottomLg};
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.overMedium}) {
    .gatsby-img-container {
      padding-left: var(--site-x-padding);
      padding-right: var(--site-x-padding);
    }

    .caption.wrap {
      padding: 0;
    }
  }
`

const ArtistAvatarsList = styled.ul`
  display: grid;
  grid-gap: 30px 20px;
  justify-items: center;
  max-width: ${props =>
    props.twoItemsInGrid ? 'calc((152px * 2) + 20px)' : null};
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  li {
    /* max-width: calc(50% - 20px); */
    width: 100%;
    min-width: 120px;
    text-align: center;

    .gatsby-image-wrapper {
      width: 120px !important;
      height: 120px !important;
      margin-bottom: 8px;

      &,
      img {
        border-radius: ${props => (props.type === 'products' ? null : '50%')};
      }
    }
  }

  @media screen and (min-width: calc((20px * 2) + (120px * 2) + 20px)) {
    grid-template-columns: ${props =>
      props.oneItemInGrid ? '1fr' : 'repeat(2, 1fr)'};
  }

  @media screen and (min-width: calc((20px * 2) + (152px * 2) + 20px + 1px)) and (max-width: calc((20px * 2) + (152px * 3) + (20px * 2))) {
    max-width: calc((152px * 2) + 20px);
  }

  @media screen and (min-width: calc((20px * 2) + (152px * 2) + 20px + 1px)) {
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));

    li {
      max-width: 152px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    max-width: ${props =>
      props.twoItemsInGrid
        ? 'calc((202px * 2) + 20px)'
        : props.theme.containerWidths.smallMedium};
    margin-top: 40px;

    li {
      min-width: 202px;
      max-width: 202px;

      .gatsby-image-wrapper {
        width: 160px !important;
        height: 160px !important;
      }
    }
  }
`

const CustomArtistSectionBody = styled.div`
  text-align: center;

  p {
    font-size: ${props => props.theme.typography.headlines.intro.fsSm};
    line-height: ${props => props.theme.typography.headlines.intro.lhSm};
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    p {
      font-size: ${props => props.theme.typography.headlines.intro.fsLg};
      line-height: ${props => props.theme.typography.headlines.intro.lhLg};
    }
  }
`

const ArtistName = styled.p`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 15px;
  }
`

const simpleKebabCase = string =>
  string
    .split(/[_\s]/)
    .join('-')
    .toLowerCase()

const ArticleTemplate = ({ data, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)

  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: false,
    }))
  }, [])

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  const {
    title,
    intro,
    heroImage,
    videoHeader,
    heroImageCaption,
    dropCap,
    body,
    afterGalleryBody,
    publishDate,
    category,
  } = data.contentfulArticle
  const articleNode = data.contentfulArticle

  const relatedArticles = data.allContentfulArticle.edges

  return (
    <>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO
        pagePath={props.pageContext.pagePath}
        articleNode={articleNode}
        articleSEO
      />

      <article
        className={`article-container article--${simpleKebabCase(
          category.title
        )}`}
      >
        <ArticleHeader>
          <Container className="wrap">
            <p className="label">{category.title}</p>
            <h1 className="headline--large">{title}</h1>
            {intro && <p className="headline--intro">{intro}</p>}
            <p className="detail">{publishDate}</p>
          </Container>
          {videoHeader ? (
            <ul style={{ width: '100%', padding: '0 16px' }}>
              <ResponsiveVideo
                videoProvider={
                  videoHeader.provider.toLowerCase() === 'vimeo'
                    ? 'vimeo'
                    : videoHeader.provider.toLowerCase() === 'youtube'
                      ? 'youtube'
                      : null
                }
                link={videoHeader.src}
                width={videoHeader.width}
                height={videoHeader.height}
              />
            </ul>
          ) : (
            <div className="gatsby-img-container">
              <figure>
                <Img fluid={heroImage.fluid} />
                {heroImageCaption && (
                  <figcaption className="wrap caption">
                    {heroImageCaption}
                  </figcaption>
                )}
              </figure>
            </div>
          )}
        </ArticleHeader>
        {(body || afterGalleryBody) && (
          <Container
            className={
              dropCap ? 'wrap body-content has-drop-cap' : 'wrap body-content'
            }
          >
            {body && (
              <div
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
            )}
            {afterGalleryBody && (
              <div
                dangerouslySetInnerHTML={{
                  __html: afterGalleryBody.childMarkdownRemark.html,
                }}
              />
            )}
          </Container>
        )}
      </article>
      {relatedArticles.length > 0 && (
        <section className="fill--nearly-white">
          <div className="container wrap padded-section">
            <h4 className="section-title">Related news</h4>
            <RelatedArticleCards articles={relatedArticles} />
          </div>
        </section>
      )}
    </>
  )
}

export const query = graphql`
  query($slug: String, $catId: String) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      intro
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      category {
        title
        id
        slug
      }
      heroImage {
        title
        description
        fluid(sizes: "(max-width: 768px) 100vw, 810px", quality: 75) {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      videoHeader {
        src
        provider
        width
        height
      }
      heroImageCaption
      dropCap
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
    allContentfulArticle(
      filter: { category: { id: { eq: $catId } }, slug: { ne: $slug } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          title
          ...RelatedArticleImageSettings
          category {
            title
          }
          publishDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

// topProducts {
//   id
//   title
//   description
//   fixed(width: 160, quality: 60) {
//     ...GatsbyContentfulFixed_withWebp
//   }
// }

export default ArticleTemplate
