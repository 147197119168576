import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

const titleClass = 'headline--xs'
const titleLHSm = theme.typography.headlines.small.lhSm
const titleLHLg = theme.typography.headlines.small.lhLg

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 10px 10px 12px;

  .${titleClass} {
    margin: 8px 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: calc(${titleLHSm} * 2);
  }

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    .${titleClass} {
      max-height: calc(${titleLHLg} * 2);
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    padding: 20px;

    .${titleClass} {
      -webkit-line-clamp: 3;
      max-height: calc(${titleLHLg} * 3);
    }
  }
`
const Category = styled.p``

const RelatedArticle = styled.li`
  background-color: white;

  > a {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    text-decoration: none;
    color: inherit;
  }

  .gatsby-image-wrapper {
    width: 28vw;
    min-width: 105px;
    height: 28vw;
    min-height: 105px;
    flex-shrink: 0;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    > a {
      flex-direction: column;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 264px;
    }
  }
`

const RelatedArticleCard = ({
  title,
  slug,
  heroImage,
  category,
  publishDate,
}) => {
  return (
    <RelatedArticle>
      <Link to={`/news/${slug}/`}>
        <Img fluid={heroImage.fluid} />
        <DetailsWrapper>
          {category && <Category className="label">{category.title}</Category>}
          <h4 className={titleClass} title={title}>
            {title}
          </h4>
          {publishDate && <p className="detail">{publishDate}</p>}
        </DetailsWrapper>
      </Link>
    </RelatedArticle>
  )
}

export default RelatedArticleCard
